import { Row, Col, Card, Table, Button, Input, Tag, DatePicker, Tooltip, Avatar, Image } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import ShowTotal from "../../components/ShowTotal";
import moment from 'moment';
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import ConfirmationBox from "../../components/ConfirmationBox";
import lang from "../../helper/lang";
import { Link } from "react-router-dom";
import { DownloadExcel } from "../../components/ExcelFile";
const Search = Input.Search;
const { RangePicker } = DatePicker;

function Index() {

  const sectionName = lang("Venue");
  const routeName = "venue-manager";

  const api = {
    list: apiPath.venueList,
    status: apiPath.venuestatus,
  }

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest()
  const { showConfirm } = ConfirmationBox()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();

  const view = (id) => {
    navigate(`/${routeName}/view/${id}`)
  }


  const columns = [
    {
      title: `${lang("Image")}`,
      dataIndex: "image",
      key: "image",
      render: (_, { name, _id, image }) => {
        return (
          <>
            {!image ? (
              <>
                <Avatar className="cap" size={40}>{name.charAt(0)}</Avatar>
              </>
            ) : (
              <>
                <Image className="image-index-radius" src={apiPath.assetURL + image} />
              </>
            )}
          </>
        );
      },
     
    },
    {
      title: `${lang("Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { name, _id, image }) => {
        return (
          <>
            {!image ? (
              <>
                <a className="cap avatar-text" >{name}</a>
              </>
            ) : (
              <>
                <a className="cap avatar-text" >{name}</a>
              </>
            )}
          </>
        );
      },
      sorter: (a, b) => {
        let nameA = a.name.toLowerCase()
        let nameB = b.name.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: `${lang("Music")} ${lang("Type")}`,
      dataIndex: "music_type",
      key: "music_type",
      render: (_, { music_type }) => {
        return music_type ? music_type : '-';
      }
    },
    {
      title: `${lang("Opening")} ${lang("Time")}`,
      render: (_, { start_time }) => {
        return (
          (start_time ?moment(start_time, 'HH:mm').format('h:mm a')   : '')
        );
      }
    },
    {
      title: `${lang("Closing")} ${lang("Time")}`,
      render: (_, { end_time }) => {
        return (
          (end_time ?moment(end_time, 'HH:mm').format('h:mm a')   : '')
        );
      }
    },
    {
      title: `${lang("Featured")}`,
      key: "is_featured",
      
      render: (_, { is_featured, _id }) => {
        let color = is_featured ? 'green' : 'red';
        const type = "is_featured";
        return (<a><Tag color={color} key={is_featured}>{is_featured ? "Yes" : "No"}</Tag></a>);
      },
    },
    {
      title: `${lang("Status")}`,
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        return (
          <a>
            <Tag onClick={(e) => showConfirm({ record: _id, path: api.status, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag>
          </a>
        );
      },
    },
    {
      title: `${lang("Registered_On")}`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
    },
    {
      title: `${lang("Action")}`,
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
          
          <Tooltip title={`${lang('Update')} ` + sectionName} color={"purple"} key={"update" + routeName}>
              <Link className="ant-btn ant-btn-default" title="Edit" to={`/${routeName}/update/` + (record ? record._id : null)}><i className="fa fa-light fa-pen"></i>
              </Link>
            </Tooltip>

            <Tooltip title={`${lang('View')} ` + sectionName} color={"purple"} key={"view" + routeName}>
              <Button title="View" onClick={(e) => view(record._id)}>
                <i className="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip>


          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText, startDate, endDate])


  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null

    request({
      url: api.list + `?status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };


  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
      setPagination({ current: 1 })

    } else {
      setStartDate()
      setEndDate()
      setPagination({ current: 1 })

    }
  };

  const excelData = list.map(row => ({
    "Name": row.name ? row.name : '-',
    "Music Type": row.music_type && Array.isArray(row.music_type) && row.music_type.length > 0
    ? row.music_type.join(", ")
    : '-',
    "Opening Time": row.start_time ? moment(row.start_time, 'HH:mm').format('h:mm a') : '-',
    "Closing Time": row.end_time ? moment(row.end_time, 'HH:mm').format('h:mm a')   : '-',
    "Featured": row.is_featured ? "Yes" : 'No',
    "Status": row.is_active ? 'Active' : 'Inactive',
    "Registered On": moment(row.created_at).format("DD-MM-YYYY")
  }));

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + " "+lang("Manager")}
              extra={
                <>
                  <Search
                    size="large"
                    onChange={onSearch}
                    value={searchText}
                    onPressEnter={onSearch}
                    allowClear
                    placeholder={`${lang("Search")} ${lang("By")} ${lang("Name")}`}
                  />
                  <div className="w-100 text-end add-export-btn">
                    <RangePicker className="d-inline-block" disabledDate={(current) => current.isAfter(Date.now())} onChange={handleChangeDate} />
                    {/* <Link className="ant-btn ant-btn-primary" to={`/${routeName}/add`}>{lang("Add")} {sectionName}</Link> */}
                    {/* <Button className="d-inline-block ms-sm-2 mt-xs-2" onClick={(e) => { setVisible(true); setSearchText(''); }}>{lang("Add")} {sectionName}</Button> */}
                    {/* <div className="button_group justify-content-end w-100">
                      <Link className="ant-btn ant-btn-primary" to={`/${routeName}/add`}>{lang("Add")} {sectionName}</Link>
                      </div> */}
                      <Button title="Export" onClick={(e) => DownloadExcel(excelData, sectionName)}><i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;{lang("Export")}</Button>
                  </div>
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['10', '20', '30', '50'] }}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>


      {/* {visible && <UserFrom type={sectionName} path={api.addEdit} sectionName={sectionName} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />} */}

      {/* {visible && <AddFrom show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />} */}

    </>
  );
}



export default Index;

import {
  Row,
  Col,
  Card,
  Button,
  Input,
  Switch,
  Form,
  Skeleton,
  Image,
  Select,
  Radio,
  InputNumber,
  Space,
  TimePicker,
} from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import SingleImageUpload from "../../components/SingleImageUpload";
import notfound from "../../assets/images/not_found.png";
import moment from "moment";
import LocationMap from "../User/LocationMap";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

function Edit() {
  const sectionName = "Venue";
  const routeName = "venue-manager";

  const api = {
    addEdit: apiPath.editVenue,
    category: apiPath.categoryList,
    subCategory: apiPath.subCategoryList,
    product: apiPath.productList,
    edit: apiPath.venueById,
  };

  const [form] = Form.useForm();
  const { request } = useRequest();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];
  const [image, setImage] = useState([]);
  const [file, setFile] = useState([]);
  const [thumbNail, setThumbnail] = useState("");

  const [switchState, setSwitchState] = useState(false);
  const [location, setLocation] = useState();
  const [tagsCount, setTagsCount] = useState();

  const handleStartTimeChange = (time, timeString) => {
    // setStartTime(timeString);
  };

  const handleEndTimeChange = (time, timeString) => {
    // setEndTime(timeString);
  };

  const fetchData = (id) => {
    request({
      url: api.edit + "/" + id,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);

        form.setFieldsValue({
          ...data,
          start_time: moment(new Date("2024-05-07T" + data.start_time)),
          end_time: moment(new Date("2024-05-07T" + data.end_time)),
          address: data.location,
          indicator: data.expense_indicator,
          is_featured: data.is_featured,
          crowd_type: data.crowd_type[0],
          tagsValue: data.tags,
        });
        setSwitchState(data.is_featured);

        setLocation({
          location: data?.location,
          latitude: data?.latitude,
          longitude: data?.longitude,
          // city: data?.city,
          // country: data?.country,
          // postal_code: data?.postal_code,
        });
        setImage(data.image);
        setFile([data.image]);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleImage = (data) => {
    console.log(data, "Image>>>>>>>>>>>>>>.");
    setImage(data[0].url);
    setThumbnail(data[0].thumbUrl);
  };

  const OnUpdate = (values) => {
    const payload = {
      ...values,
      image: image,
      venue_id: params.id,
      map_icon: thumbNail,

      latitude: values.location.latitude,
      location: values.location.location,
      longitude: values.location.longitude,
      // tags: values.tagsValue.map((t)=> t.tag)
    };
    console.log(values, "tags>>>>>>>>");
    payload["start_time"] = moment(payload.start_time).format("HH:mm");
    payload["end_time"] = moment(payload.end_time).format("HH:mm");
    if (payload.start_time.split(":")[0].length < 2) {
      payload["start_time"] = 0 + payload.start_time;
    }
    if (payload.end_time.split(":")[0].length < 2) {
      payload["end_time"] = 0 + payload.end_time;
    }
    setLoading(true);
    request({
      url: api.addEdit,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          navigate(`/${routeName}`);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleLocationChange = (value) => {
    setLocation(value);
  };

  useEffect(() => {
    setLoading(true);
    fetchData(params.id);
  }, []);

  const crowdType = [
    "Young Professionals",
    "College Students",
    "Tourists",
    "Social Butterflies",
    "Couples",
    "Regulars",
    "Older",
    "Fashionistas",
  ];
  const musicType = ["House music", "Techno", "Edm"];

  const indicator = ["$", " $$", "$$$", "$$$$"];
  const addCoverChargeField = () => {
    setTagsCount(tagsCount + 1);
  };

  return (
    <>
      {loading ? (
        [1, 2, 3, 4].map((item) => <Skeleton active key={item} />)
      ) : (
        <Form
          className="edit-page-wrap colPadding"
          form={form}
          onFinish={OnUpdate}
          autoComplete="off"
          layout="verticle"
          name="subscription_form"
        >
          <Card title={"Update " + sectionName}>
            <Row gutter={[24, 0]}>
              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  normalize={(value) => value.trimStart()}
                  label="Name"
                  name="name"
                  rules={[
                    { required: true, message: "Enter Venue name!" },
                  ]}
                >
                  <Input autoComplete="off" placeholder="Enter Venue Name" />
                </Form.Item>
              </Col>

              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  normalize={(value) => value.trimStart()}
                  label="Expense Indicator"
                  name="expense_indicator"
                  // rules={[{ required: true, message: "Enter Icon or Symbol" }]}
                >
                  <Select
                    style={{ width: "100%" }}
                    filterOption={(inputValue, option) =>
                      option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    }
                    placeholder="Select expense indicator"
                    options={indicator.map((v) => ({
                      label: v,
                      value: v,
                    }))}
                  />
                </Form.Item>
              </Col>

              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  /* rules={[
                                        {
                                            validator: (_, value) => {
                                                if (value !== undefined && value?.length > 0) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Missing Image!'));
                                            },
                                        }
                                    ]} */
                  label="Upload Logo"
                  name="logo"
                >
                  <SingleImageUpload
                    value={image}
                    fileType={FileType}
                    imageType={"venue"}
                    btnName={"Image"}
                    onChange={(data) => handleImage(data)}
                  />

                  {image && image.length > 0 && (
                    <div className="mt-2">
                      {" "}
                      <Image
                        width={120}
                        src={image !== "" ? image : notfound}
                      ></Image>{" "}
                    </div>
                  )}
                </Form.Item>
              </Col>
              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  className="music-type"
                  name="music_type"
                  label="Music Types"
                  rules={[
                    { required: true, message: "Missing Music Type Selection" },
                  ]}
                >
                  {/* <Select
                                        style={{
                                        width: '100%',
                                        }}tag
                                        showSearch  // Enable search functionality
                                        filterOption={(inputValue, option) =>
                                        option.label.toLowerCase().includes(inputValue.toLowerCase())
                                        }
                                        placeholder="Select music"
                                        onChange={(value) => form.setFieldValue("music_type", value)}
                                        options={musicType.map(v => ({
                                        label: v,
                                        value: v
                                        }))}
                                    />  */}
                  <Input placeholder="Enter music type" />
                </Form.Item>
              </Col>
              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  className="dress-code"
                  name="dress_code"
                  label="Dress Code"
                  rules={[{ required: true, message: "Missing Dress Code" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  className="Tags"
                  name="tags"
                  label="Tags"
                  rules={[{ required: true, message: "Missing Tags" }]}
                >
                  <Select
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    tag
                    showSearch // Enable search functionality
                    filterOption={(inputValue, option) =>
                      option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    }
                    placeholder="Select tags"
                    onChange={(value) => form.setFieldValue("tags", value)}
                    options={musicType.map((v) => ({
                      label: v,
                      value: v,
                    }))}
                  />
                  {/* <Input placeholder="Enter music type" /> */}
                </Form.Item>
              </Col>
              {/* <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                                <Button type="dashed" onClick={addCoverChargeField} block>
                                    <PlusOutlined /> Add Tags
                                </Button>
                            </Col> */}

              <Col span={24} sm={12}>
                <Form.Item label="Featured" name="is_featured">
                  <Switch
                    checked={switchState}
                    onChange={(checked) => setSwitchState(checked)}
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  label="Start Time"
                  name="start_time"
                  rules={[
                    { required: true, message: "Please select a start time" },
                  ]}
                >
                  <TimePicker
                    style={{
                      width: "100%",
                      height: "42px",
                      borderRadius: "6px",
                    }}
                    format="hh:mm a"
                    onChange={handleStartTimeChange}
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  label="End Time"
                  name="end_time"
                  rules={[
                    { required: true, message: "Please select an end time" },
                  ]}
                >
                  <TimePicker
                    style={{
                      width: "100%",
                      height: "42px",
                      borderRadius: "6px",
                    }}
                    format="hh:mm a"
                    onChange={handleEndTimeChange}
                  />
                </Form.Item>
              </Col>
              <Col pan={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  normalize={(value) => value.trimStart()}
                  label="Crowd Type"
                  name="crowd_type"
                  rules={[
                    {
                      required: true,
                      message: "MissingCrowd Type Description!",
                    },
                  ]}
                >
                  {/* <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        showSearch  // Enable search functionality
                                        filterOption={(inputValue, option) =>
                                            option.label.toLowerCase().includes(inputValue.toLowerCase())
                                        }
                                        placeholder="Select Crowd"
                                        onChange={(value) => form.setFieldValue("crowd_type", value)}
                                        options={crowdType.map(v => ({
                                            label: v,
                                            value: v
                                        }))}
                                    />                            */}

                  <Input placeholder="Enter crowd type" />
                </Form.Item>
              </Col>
              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  normalize={(value) => value.trimStart()}
                  label="Cover charges"
                  name="cover_charges"
                  rules={[{ required: true, message: "Missing Charges!" }]}
                >
                  <Input
                    maxLength={180}
                    autoComplete="off"
                    placeholder="Enter charges"
                  />
                </Form.Item>
              </Col>

              <Col span={24} sm={24} md={24} lg={24} xl={24} xxl={18}>
                <Form.Item
                  normalize={(value) => value.trimStart()}
                  label="Bio (100 Characters)"
                  name="bio"
                  rules={[{ required: true, message: "Missing Description!" }]}
                >
                  <Input
                    maxLength={180}
                    autoComplete="off"
                    placeholder="Enter Description"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} md={24} lg={24} xl={24} xxl={18}>
                <Form.Item
                  normalize={(value) => value.trimStart()}
                  label="Highlight/Detailed Introduction (100 Characters)"
                  name="detailed_description"
                  rules={[{ required: true, message: "Missing Description!" }]}
                >
                  <Input
                    maxLength={180}
                    autoComplete="off"
                    placeholder="Enter Description"
                  />
                </Form.Item>
              </Col>

              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  /* rules={[
                                        {
                                            validator: (_, value) => {
                                                if (value !== undefined && value?.length > 0) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Missing Image!'));
                                            },
                                        }
                                    ]} */
                  label="Upload Background Image"
                  name="image"
                >
                  <SingleImageUpload
                    value={image}
                    fileType={FileType}
                    imageType={"venue"}
                    btnName={"Image"}
                    onChange={(data) => handleImage(data)}
                  />

                  {file && file.length > 0 && (
                    <div className="mt-2">
                      {" "}
                      <Image
                        width={120}
                        src={file !== "" ? file : notfound}
                      ></Image>{" "}
                    </div>
                  )}
                </Form.Item>
              </Col>
              <Col md={24}>
                <Form.Item
                  label="Location" // (Drag Marker for Selecting Location)
                  name="location"
                  rules={[
                    { required: true, message: "Please select the location!" },
                  ]}
                >
                  <LocationMap
                    onChange={handleLocationChange}
                    editLocation={location}
                    // userData={data}
                    //   type={type}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Form.Item className="btn-row float-right mb-3 mt-3">
            <Link className="ant-btn ant-btn-primary" to={`/${routeName}`}>
              Back
            </Link>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
}

export default Edit;
